<template>
  <div class="pa-2 text-center home-text-2 slots__not-available">
    <p class="">
      <img src="/img/icons/not-available.svg" alt="No slots available" />
    </p>
    <div class="">
      <v-row>
        <v-col cols="12">
          <h3 class="text-center text-subtitle-1">
            There is no availability in this time slot please continue to search alternative times and dates.
          </h3>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style scoped>
.slots__not-available {
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}
</style>
