













































































.schedule {
  max-width: 800px;
  margin: 0 auto;
}
