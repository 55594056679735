









































































































































































































































































@media screen and (max-width: 960px) {
    .slots-sheet {
      overflow-x: scroll;
  }
}

.slots-column {
  width: 85px;
}

.slot {
  align-self: center;
}

.direction-button-wrapper {
  position: relative;
}

.direction-button {
  position: absolute !important;
  &.left {
    top: 20px;
    left: -25px;
  }
  &.right {
    top: 20px;
    right: -25px;
  }
}

